@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');

body {
  margin: 0;
  font-family: "Almarai", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .bg-color{
 border: 2px solid #dddcdc;
  border-radius: 15px;
} */
.btn-color{
  background-color: #282827 !important;
  color: white !important;
}
.linkslider{
  /* border-radius: 15px; */
  color: black;
  transition: 0.5s;
  text-align: center;
  display: block;
}
.linkslider:hover{
  background-color: #dfdad0;
  /* color: white; */
}
.bg_dark_color{
  background-color: #dfdad0 !important;
  /* color: white !important; */
  /* border-radius: 15px; */
}
.bg_dark_color:hover{
  background-color: #dfdad0 !important;
  /* color: white !important; */
  border-radius: 0px;
}
.imgtable{
  width: 50px;
  height: 50px;
}
.btnh-10{
  height: 40px;
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y)* .5) calc(var(--bs-modal-header-padding-x)* .5);
  margin: 0 !important;
  /* margin: calc(-.5* var(--bs-modal-header-padding-y)) calc(-.5* var(--bs-modal-header-padding-x)) calc(-.5* var(--bs-modal-header-padding-y)) auto; */
}
.bg-logout{
  background-color: #f00a0a96;
  /* border-radius: 20px; */
  color: white !important;
  cursor: pointer;
}
.bg-logout:hover{
  background-color: #f00a0a96 !important;
  /* border-radius: 20px; */
  color: white !important;
  text-align: center !important;
}
.mt-160{
  margin-top: 160px;
}
.mt-140{
  margin-top: 140px;
}

.sidebar-transition {
  transition: all 0.3s ease;
}

.sidebar-expanded {
  width: 25%;
}

.sidebar-collapsed {
  width: 8%;
}

.content-transition {
  transition: all 0.3s ease;
}

.content-expanded {
  width: 92%;
}

.content-collapsed {
  width: 75%;
}
.bg-alert{
  background-color: #00800073;
  border-radius: 5px;
  color: white !important;
  cursor: pointer;
}
.iconeimg{
  width: 35px;
  height: 35px;
}
.ml-14{
  margin-left: -21px;
}